import React from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import logoFooter from "../../images/logos/logoFooter.png";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footerContainer">
      <div className="footerLogoImage">
        <img src={logoFooter} alt="logoFooter" className="footerLogoImg" />
      </div>
      <div className="footerLinks">
        <p onClick={() => navigate("/aboutus")}>About Us</p>
        <p onClick={() => navigate("/privacy")}>Privacy</p>
      </div>
      <div className="footerCopyright">
        <p>&copy; 2024 Cook What You Got</p>
      </div>
    </div>
  );
};

// <div className="footerLeft">
// <div className="footerLogo">
//   <img src={logoFooter} alt="logoFooter" className="footerLogoImg" />
// </div>
// <div className="footerLinks">
//   <p>About Us</p>
//   <p>Privacy</p>
// </div>
// </div>
// <div className="footerRight">

// <div className="footerCopyright">
//   <p>&copy; 2024 Cook What You Got</p>
// </div>
// </div>
export default Footer;
