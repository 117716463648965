import { React, useEffect, useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import DashboardFooter from "../Footer/DashboardFooter";
import Footer from "../Footer/Footer";
import camera from "../../images/dashboard/camera.png";
import RecipeResults from "../RecipeResults/RecipeResults";
import DashboardNav from "../Nav/DashboardNav";
import { auth } from "../../config/firebase";
import { onAuthStateChanged } from "firebase/auth";

const Dashboard = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  const [ingredients, setIngredients] = useState("");
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const getIngredientsList = () => {
    const ingredients = localStorage.getItem("ingredientList");
    if (ingredients) {
      return JSON.parse(ingredients);
    } else {
      return [];
    }
  };
  const [ingredientsList, setIngredientsList] = useState(getIngredientsList());
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log("user logged in", user);
        setUserLoggedIn(true);
      } else {
        // console.log("user not logged in");
        setUserLoggedIn(false);
      }
    });
    // console.log("ingredientsList", ingredientsList);

    setIngredientsList(getIngredientsList());

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAddIngredient = (e) => {
    e.preventDefault();
    if (ingredients === "") return;
    if (ingredientsList.includes(ingredients)) return;
    if (ingredients.includes(",")) {
      const ingredientsArray = ingredients.split(",");
      // console.log(ingredientsArray);
      let newIngredientList = [];
      ingredientsArray.forEach((ingredient) => {
        if (ingredient.trim() !== "") {
          // console.log(ingredient.trim());
          newIngredientList.push(ingredient.trim());
        }
      });

      setIngredientsList([...ingredientsList, ...newIngredientList]);
      setIngredients("");

      return;
    }
    setIngredientsList([...ingredientsList, ingredients]);
    setIngredients("");
  };

  const handleRemoveTag = (index) => {
    const newIngredientsList = ingredientsList.filter(
      (ingredient, i) => i !== index
    );
    setIngredientsList(newIngredientsList);
  };
  return (
    <div className="dashBoardContainer">
      <div className="dashboardNavContainer">
        <DashboardNav dashboardActivePage="home" />
      </div>
      <div className="dashboardBody">
        <div className="dashBoardTop">
          <div className="dashBoardTopLeft">
            <div className="dashBoardTopLeftHeading">
              <p className="dashBoardTitle">Add Ingredients</p>
              <p className="note">
                Please click 'Add' or press 'Enter' to add ingredients.
              </p>
              <form className="ingredientsInput" onSubmit={handleAddIngredient}>
                <input
                  type="text"
                  value={ingredients}
                  placeholder="Eg: tomato"
                  onChange={(e) => setIngredients(e.target.value)}
                />
                <button
                  className="addIngredients"
                  onClick={handleAddIngredient}
                  type="submit"
                  disabled={!ingredients}
                >
                  Add
                </button>
              </form>
            </div>
            <div className="dashBoardTopLeftTags">
              {ingredientsList.map((ingredient, index) => (
                <div key={index} className="ingredientTag">
                  <li>
                    {ingredient}{" "}
                    <button
                      className="removeIngredientTag"
                      onClick={() => handleRemoveTag(index)}
                    >
                      X
                    </button>
                  </li>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="orDiv">
            <p>OR</p>
          </div>
          <div className="dashBoardTopRight">
            <img src={camera} alt="camera" className="cameraDashboard" />
            <button className="captureDashboardButton">
              Capture Ingredients
            </button>
          </div> */}
        </div>
        <hr className="dashboardHr" />
        <div className="dashBoardResults">
          <div className="dashBoardFilter"></div>
          <div className="dashBoardResultDisplay">
            <RecipeResults ingredientList={ingredientsList} />
          </div>
        </div>
      </div>

      {isLargeScreen ? (
        <Footer />
      ) : (
        <DashboardFooter activeFooter="homeFooter" />
      )}
    </div>
  );
};

export default Dashboard;
