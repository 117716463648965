import React from "react";
import "./styles.css";
import Landingnav from "../Nav/Landingnav";
import Footer from "../Footer/Footer";
import marcus from "../../images/aboutUs/marcus.jpeg";
import aryansh from "../../images/aboutUs/aryansh.jpg";
import kevin from "../../images/aboutUs/kevin.jpg";

const Aboutus = () => {
  return (
    <div className="aboutUsContainer">
      <div className="navContainerAboutUs">
        <Landingnav activePage="aboutus" />
      </div>
      <div className="aboutUsBody">
        <div className="aboutUsInfo">
          <h1>About Us</h1>
          <p>
            We built this web app to make it easier for us and other people to
            find recipes online based on what they have available in their
            kitchen.
          </p>
          <hr />
        </div>

        {/* team */}
        <div className="teamSection">
          <h1>Team</h1>
          <div className="teamMemberList">
            <div className="teamMember">
              <img src={marcus} alt="marcus" className="teamMemberImage" />
              <h3>Marcus</h3>
              <p className="profession">Mechanical Engineer</p>
              <p>
                Marcus is a Mechanical Engineer with a passion for solving
                problems. He loves a hands on approach to building things and is
                always looking widening his skill set.
              </p>
            </div>
            <div className="teamMember">
              <img src={kevin} alt="kevin" className="teamMemberImage" />
              <h3>Kevin</h3>
              <p className="profession">Software Developer</p>
              <p>
                Kevin is a Software Developer who enjoys building solutions to
                real world problems. He is proficient in both backend and
                frontend technologies.
              </p>
            </div>
            <div className="teamMember">
              <img src={aryansh} alt="aryansh" className="teamMemberImage" />
              <h3>Aryansh</h3>
              <p className="profession">Software Developer</p>
              <p>
                Aryansh is a Software Developer who is passionate about building
                applications that provide value to the user. He is also
                proficient in both backend and frontend technologies.
              </p>
            </div>
            {/* <div className="teamMember">
              <he>Eric Lichtenstein</he>
              <p className="profession">Software Developer</p>
              <p>
                Eric is the third Software Developer in the team. He loves
                learning new tech to broaden his skill set and is often ready to
                dive into new projects.
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Aboutus;
