import { React, useState } from "react";
import "./styles.css";
import Landingnav from "../Nav/Landingnav";
import Footer from "../Footer/Footer";
import { auth, provider } from "../../config/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import google from "../../images/signup/google.png";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  //regular email and password sign up
  const signUpClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setValidationMessage("");
    if (password !== confirmPassword) {
      setValidationMessage("Passwords do not match, please try again.");
      return;
    }
    validatePassword(password);
    if (!passwordValid) {
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      // console.log(user);
      var actionCodeSettings = {
        url: "https://cook-what-you-got.web.app/login",

        handleCodeInApp: true,
      };
      await sendEmailVerification(user, actionCodeSettings);
      //   await sendEmailVerification(user);
      navigate("/email-verify");
      //   navigate("/dashboard");
    } catch (error) {
      console.log(error, error.code);
      setIsSubmitting(false);
      if (error.code === "auth/email-already-in-use") {
        // console.log("Email already in use.");
        setValidationMessage("Email already in use, please log in.");
        setEmailValid(false);
      }
    }
  };

  //google sign up
  const signInWithGoogle = async () => {
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      // console.log(user);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  //password validation check
  const validatePassword = (password) => {
    const minLengthRegex = /.{8,}/; // At least 8 characters
    const uppercaseRegex = /[A-Z]/; // At least one uppercase letter
    const specialCharRegex = /[\W_]+/; // At least one special character (non-word characters)

    if (!minLengthRegex.test(password)) {
      setValidationMessage("Password must be at least 8 characters long.");
      setPasswordValid(false);
    } else if (!uppercaseRegex.test(password)) {
      setValidationMessage(
        "Password must contain at least one uppercase letter."
      );
      setPasswordValid(false);
    } else if (!specialCharRegex.test(password)) {
      setValidationMessage(
        "Password must contain at least one special character."
      );
      setPasswordValid(false);
    } else {
      setValidationMessage("Password is valid.");
      setPasswordValid(true);
    }
  };
  return (
    <div className="signUpContainer">
      <div className="navSignUp">
        <Landingnav activePage="signup" />
      </div>
      <div className="signUpBody">
        <div className="signUpForm">
          <div className="signUpLabel">
            <div className="signUpHeading">
              <h1>Sign Up</h1>
              <hr />
            </div>
            <p>
              Already have an account?{" "}
              <span className="logInSpan" onClick={() => navigate("/login")}>
                Log in
              </span>
            </p>
          </div>
          <button className="googleSignInButton" onClick={signInWithGoogle}>
            <img src={google} alt="google" className="googleIcon" />
            Sign in with Google
          </button>
          <form className="signUpFormSubmit" onSubmit={(e) => signUpClicked(e)}>
            <div className="emailSignUp">
              <label>Email</label>
              <input type="email" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="passwordSignup">
              <label>Password</label>
              <div className="passwordContainer">
                <input
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  onClick={togglePasswordVisiblity}
                  className="passwordIcon"
                />
              </div>
            </div>
            <div className="confirmPasswordSignup">
              <label>Confirm Password</label>
              <div className="passwordContainer">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                  onClick={toggleConfirmPasswordVisiblity}
                  className="passwordIcon"
                />
              </div>
            </div>
            {!passwordValid && (
              <div className="passwordValidationMessage">
                <p className="errorMessage">{validationMessage}</p>
              </div>
            )}
            {!emailValid && (
              <div className="emailValidationMessage">
                <p className="errorMessage">{validationMessage}</p>
              </div>
            )}
            <div className="signUpButtonContainer">
              <button
                onClick={signUpClicked}
                className="signUpButton"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Signing up..." : "Sign Up"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
