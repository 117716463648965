import React from "react";
import Landingnav from "../Nav/Landingnav";
import Footer from "../Footer/Footer";
import "./styles.css";
import ingredients from "../../images/homePage/ingredients.png";
import tiktokSection from "../../images/homePage/tiktokSection.png";
import arrowLeft from "../../images/homePage/arrowLeft.png";
import arrowRight from "../../images/homePage/arrowRight.png";
import Faq from "../Faq/Faq";
import searchRecipe from "../../images/homePage/howItWorks/searchRecipe.png";
import resultsRecipeOne from "../../images/homePage/howItWorks/resultsRecipeOne.png";
import resultsRecipeTwo from "../../images/homePage/howItWorks/resultsRecipeTwo.png";
import saveRecipeOne from "../../images/homePage/howItWorks/saveRecipeOne.png";
import saveRecipeTwo from "../../images/homePage/howItWorks/saveRecipeTwo.png";

import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="homeContainer">
      <div className="navContainer">
        <Landingnav activePage="home" />
      </div>
      <div className="homeBody">
        {/* home intro */}
        <div className="homeIntro">
          <div className="homeIntroLeft">
            <div className="homeIntroHeading">
              <h1>Cook What You Got</h1>
              <hr />
            </div>
            <p>From Your Pantry to Your Plate: Effortless Meals, Every Day.</p>
            <div className="homeIntroButtons">
              <button
                className="tryItButton"
                onClick={() => navigate("/dashboard")}
              >
                Try It
              </button>
              <button
                className="homeIntroSignInButton"
                onClick={() => navigate("/signup")}
              >
                Sign Up
              </button>
            </div>
          </div>
          <div className="homeIntroRight">
            <img src={ingredients} className="ingredientsImage" />
          </div>
        </div>

        {/* how it works */}
        <div className="homeHowItWorks">
          <h3>How It Works</h3>
          <div className="howItWorksList">
            <div className="howItWorksItem stepOneHowItWorks">
              {/* <h4 className="stepOneText">Step 1</h4> */}
              <div className="howItWorksSteps">
                <p className="stepOneText">
                  Select Ingredients you have available in your fridge or
                  pantry.
                </p>
                <div className="howItWorksImageContainer">
                  <img src={searchRecipe} className="howItWorksImage" />
                </div>
              </div>
              {/* <div className="howItWorksSteps">
                <p>
                  ...or even easier, use your camera to capture the ingredients.
                </p>
              </div> */}
            </div>

            <div className="howItWorksItem stepTwoHowItWorks">
              <div className="howItWorksSteps">
                <p>
                  Choose from a list of recipes and meals you can make
                  instantly.{" "}
                </p>
                <div className="howItWorksImageContainer">
                  <img src={resultsRecipeOne} className="howItWorksImage" />
                  <img src={resultsRecipeTwo} className="howItWorksImage" />
                </div>
              </div>
            </div>

            <div className="howItWorksItem stepThreeHowItWorks">
              <div className="howItWorksSteps">
                <p>
                  Save your favorite recipes to come back to them at any time.
                </p>
                <div className="howItWorksImageContainer">
                  <img src={saveRecipeOne} className="howItWorksImage" />
                  <img src={saveRecipeTwo} className="howItWorksImage" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* tiktok section */}
        {/* <div className="tiktokSection">
          <div className="tiktokSectionLabel">
            <h3>Love tiktok recipes? We got you!</h3>
            <p>
              Get tiktok recipes instantly based on your preferences and
              ingredients.
            </p>
          </div>
          <div className="tiktokSectionImageContainer">
            <img src={tiktokSection} className="tiktokSectionImage" />
          </div>
        </div> */}

        {/* testimonies section */}
        <div className="testimoniesSection">
          <h3>Testimonies</h3>
          <div className="testimoniesList">
            {/* <img src={arrowLeft} className="arrowLeftTestimony arrow" /> */}
            <div className="testimony">
              <p>
                "This app has saved me so much time, I no longer have to worry
                about jumping from one youtube video to another to find the
                right recipe!"
              </p>
              <div className="testimonyUser">
                <p>- Kevin Wu</p>
              </div>
            </div>
            {/* <img src={arrowRight} className="arrowRightTestimony arrow" /> */}
          </div>
        </div>

        {/* faq section */}
        <div className="faqSection">
          <Faq />
        </div>
      </div>
      <div className="footerContainer">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
