import React, { useEffect, useState } from "react";
import "./styles.css";
import homeDark from "../../images/dashboardFooter/homeDark.png";
import { useNavigate } from "react-router-dom";
import recipes from "../../images/dashboardFooter/recipes.png";
import settings from "../../images/dashboardFooter/settings.png";
import { auth } from "../../config/firebase";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHouse,
  faBook,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

const DashboardFooter = ({ activeFooter }) => {
  const navigate = useNavigate();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log("user logged in", user);
        setUserLoggedIn(true);
      } else {
        // console.log("user not logged in");
        setUserLoggedIn(false);
      }
    });
  }, []);

  const signOutClicked = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const settingsIconClicked = () => {
    activeFooter = "settingsFooter";
    setSettingsOpen(!settingsOpen);
  };
  return (
    <div className="dashboardFooterContainer">
      <div
        className={`homeFooter ${
          activeFooter === "homeFooter" ? "homeFooterActive" : ""
        }`}
      >
        {/* {" "}
        <img
          src={homeDark}
          alt="homeDark"
          className="homeDark"
          onClick={() => navigate("/dashboard")}
        /> */}
        <FontAwesomeIcon
          className="faIcons"
          icon={faHouse}
          onClick={() => navigate("/dashboard")}
        />
      </div>

      <div
        className={`recipeFooter ${
          activeFooter === "recipeFooter" ? "recipeFooterActive" : ""
        }`}
      >
        {/* <img
          src={recipes}
          alt="recipes"
          className="recipes"
          onClick={() => navigate("/saved-recipes")}
        /> */}
        <FontAwesomeIcon
          className="faIcons"
          icon={faBook}
          onClick={() => navigate("/saved-recipes")}
        />
      </div>

      <div
        className={`settingsFooter ${
          settingsOpen ? "settingsFooterActive" : ""
        }`}
      >
        {settingsOpen &&
          (userLoggedIn ? (
            <div className="settingsDropdownSignOut">
              <p onClick={() => signOutClicked()}>Sign Out</p>
            </div>
          ) : (
            <div className="settingsDropdown">
              <p onClick={() => navigate("/login")}>Log In</p>
              <hr />
              <p onClick={() => navigate("/signup")}>Sign Up</p>
            </div>
          ))}
        {userLoggedIn ? (
          //   <img
          //     src={settings}
          //     alt="settings"
          //     className="settings"
          //     onClick={() => settingsIconClicked()}
          //   />
          <FontAwesomeIcon
            className="faIcons"
            icon={faGear}
            onClick={() => settingsIconClicked()}
          />
        ) : (
          <FontAwesomeIcon
            className="faIcons"
            icon={faBars}
            onClick={() => settingsIconClicked()}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardFooter;
