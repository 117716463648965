import { React, useEffect, useState } from "react";
import "./styles.css";
import logo from "../../images/logos/logo.png";
import { useNavigate } from "react-router-dom";
import Rightnav from "./Rightnav";
import Burger from "./Burger";

const Landingnav = ({ activePage }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`landingNavContainer ${isScrolled ? "scrolled" : ""}`}>
      <div className="logoContainer">
        <img
          src={logo}
          alt="logo"
          className="landingLogo"
          onClick={(e) => navigate("/")}
        />
      </div>
      <Burger activePage={activePage} />
    </div>
  );
};

export default Landingnav;
