import React, { useState, useEffect } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logos/logo.png";
import { auth } from "../../config/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

const DashboardNav = ({ dashboardActivePage }) => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log("user logged in", user);
        setUserLoggedIn(true);
      } else {
        // console.log("user not logged in");
        setUserLoggedIn(false);
      }
    });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const settingsClicked = () => {
    setSettingsOpen(!settingsOpen);
  };

  const signOutClicked = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={`dashboardNavContainer ${isScrolled ? "scrolled" : ""}`}>
      <div className="dashLogo">
        <img
          src={logo}
          alt="logo"
          className="landingLogo"
          onClick={() => navigate("/dashboard")}
        />
      </div>

      <ul className="dashboardUl">
        <div className="dashHome">
          <li
            className={`dashboardNav ${
              dashboardActivePage === "home" ? "dashActive" : ""
            }`}
            onClick={(e) => navigate("/dashboard")}
          >
            Home
          </li>
          <hr
            className={`navHr ${
              dashboardActivePage === "home" ? "display" : ""
            }`}
          />
        </div>

        <div className="dashRecipe">
          <li
            className={`dashboardNav ${
              dashboardActivePage === "recipe" ? "dashActive" : ""
            }`}
            onClick={(e) => navigate("/saved-recipes")}
          >
            Recipes
          </li>
          <hr
            className={`navHr ${
              dashboardActivePage === "recipe" ? "display" : ""
            }`}
          />
        </div>
        <div className="settingsNav">
          <li
            className={`dashboardNav ${
              dashboardActivePage === "settings" ? "dashActive" : ""
            }`}
            onClick={() => settingsClicked()}
          >
            {userLoggedIn ? "Settings" : "Log In/Sign Up"}
          </li>
          <hr
            className={`navHr ${
              dashboardActivePage === "settings" ? "display" : ""
            }`}
          />
          {settingsOpen && (
            <div className="signOutDropDown">
              {userLoggedIn ? (
                <p onClick={() => signOutClicked()}>Sign Out</p>
              ) : (
                <div className="settingsDropdown2">
                  <p onClick={() => navigate("/login")}>Log In</p>
                  <hr />
                  <p onClick={() => navigate("/signup")}>Sign Up</p>
                </div>
              )}
            </div>
          )}
        </div>
      </ul>
    </div>
  );
};

export default DashboardNav;
