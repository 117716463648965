import React, { useEffect, useState } from "react";
import "./styles.css";
import RecipeInfo from "../RecipeInfo/RecipeInfo";
import { useNavigate } from "react-router-dom";

const RecipeResults = ({ ingredientList }) => {
  const [recipeResults, setRecipeResults] = useState([]);
  const [recipeWithNutrients, setRecipeWithNutrients] = useState([]); // [ {recipeId: 123, nutrients: {calories: 123, fat: 123, ...} }
  const [carbs, setCarbs] = useState(0);
  const [fat, setFat] = useState(0);
  const [protein, setProtein] = useState(0);
  const [calories, setCalories] = useState(0);
  const navigate = useNavigate();

  const recipeClicked = (id, calories, protein, fat, carbs) => {
    // console.log(id);
    // console.log(calories);
    // console.log(protein);
    // console.log(fat);
    // console.log(carbs);
    navigate(`/recipe-info/${id}`, {
      state: {
        recipeId: id,
        calories: calories,
        protein: protein,
        fat: fat,
        carbs: carbs,
      },
    });
  };

  const saveIngredientsToLocalStorage = (ingredients) => {
    localStorage.setItem("ingredientList", JSON.stringify(ingredients));
  };

  useEffect(() => {
    if (ingredientList.length > 0) {
      saveIngredientsToLocalStorage(ingredientList);

      const fetchRecipes = async () => {
        // console.log(ingredientList);
        const ingredientString = ingredientList.join(",");
        // console.log(ingredientString);
        //const url = `https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/findByIngredients?ingredients=${ingredientString}&number=5&ignorePantry=true&ranking=1`;
        const url = `https://api.spoonacular.com/recipes/findByIngredients?ingredients=${ingredientString}&number=5&ignorePantry=true&ranking=1&apiKey=${process.env.REACT_APP_SPOONACULAR_API_KEY}`;
        const options = {
          method: "GET",
          // headers: {
          //   "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
          //   "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
          // },
        };
        let oldRecipeResults = [];
        try {
          const response = await fetch(url, options);
          const result = await response.json();
          oldRecipeResults = result;
        } catch (error) {
          console.error(error);
        }

        const nutrientPromises = oldRecipeResults.map(async (recipe) => {
          //const url = `https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/${recipe.id}/nutritionWidget.json`;
          const url = `https://api.spoonacular.com/recipes/${recipe.id}/nutritionWidget.json?apiKey=${process.env.REACT_APP_SPOONACULAR_API_KEY}`;
          const options = {
            method: "GET",
            // headers: {
            //   "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
            //   "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
            // },
          };

          try {
            const response = await fetch(url, options);
            const result = await response.json();
            // console.log(result);
            return { ...recipe, nutrients: result };
          } catch (error) {
            console.error(error);
          }
        });

        const recipeWithNutrients = await Promise.all(nutrientPromises);
        setRecipeWithNutrients(recipeWithNutrients);
        // console.log("here");
        // console.log("recipeResults", recipeResults);
        // console.log("recipeWithNutrients", recipeWithNutrients);
      };
      fetchRecipes();
    }
  }, [ingredientList]);
  return (
    <div className="recipeResultsContainer">
      Recipe Results
      {ingredientList.length > 0 ? (
        <div className="recipeResultsDisplay">
          {recipeWithNutrients.map((recipe) => (
            <div
              className="recipeCard"
              key={recipe.id}
              onClick={() =>
                recipeClicked(
                  recipe.id,
                  recipe.nutrients.calories,
                  recipe.nutrients.protein,
                  recipe.nutrients.fat,
                  recipe.nutrients.carbs
                )
              }
            >
              <img
                src={recipe.image}
                alt={recipe.title}
                className="recipeImage"
              />
              <h3 className="recipeTitle">{recipe.title}</h3>
              <p className="recipeNutrients">
                {recipe.nutrients.calories} calories, {recipe.nutrients.protein}{" "}
                protein , {recipe.nutrients.fat} fat, {recipe.nutrients.carbs}{" "}
                carbs
              </p>
              <div className="ingredientListContainer">
                <p>Ingredients</p>
                <ul className="ingredientList">
                  {[...recipe.usedIngredients, ...recipe.missedIngredients].map(
                    (ingredient, index) => (
                      <li key={index}>{ingredient.original}</li>
                    )
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="recipeResultsDisplay">
          <p className="nomatches">
            No matches found, please select more ingredients.
          </p>
        </div>
      )}
    </div>
  );
};

export default RecipeResults;
