import logo from "./logo.svg";
import "./App.css";
import { Routes, Router, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Aboutus from "./components/Aboutus/Aboutus";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Dashboard from "./components/Dashboard/Dashboard";
import Recipe from "./components/SavedRecipes/Recipe";
import RecipeInfo from "./components/RecipeInfo/RecipeInfo";
import EmailVerify from "./components/EmailVerify/EmailVerify";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Privacy from "./components/Privacy/Privacy";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/email-verify" element={<EmailVerify />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/saved-recipes" element={<Recipe />} />
        <Route path="/recipe-info/:recipeId" element={<RecipeInfo />} />
      </Routes>
    </div>
  );
}

export default App;
