import React, { useEffect, useState } from "react";
import "./styles.css";
import DashboardNav from "../Nav/DashboardNav";
import DashboardFooter from "../Footer/DashboardFooter";
import Footer from "../Footer/Footer";
import { auth, db } from "../../config/firebase";
import { getDocs, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

const Recipe = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const [savedRecipes, setSavedRecipes] = useState([]);
  const [userid, setUserid] = useState("");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSavedRecipes([]);
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);

    //check if user is logged and set user id
    let userIdInsideUseEffect = "";
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserid(user.uid);
        userIdInsideUseEffect = user.uid;
        setUserLoggedIn(true);
        // console.log(user.uid);
      }
    });

    //get recipes
    const querySnapshot = async () => {
      // console.log("running query snapshot");
      const newRecipes = [];
      const snapshot = await getDocs(collection(db, "recipes"));
      // console.log(snapshot);
      snapshot.forEach((doc) => {
        // console.log("in the loop", doc.data());
        if (doc.data().userId === userIdInsideUseEffect) {
          // console.log("found a match");
          newRecipes.push(doc.data());
        }
      });
      setSavedRecipes(newRecipes);
    };
    querySnapshot();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const recipeInfoClicked = (recipeId) => {
    // console.log(recipeId);
    navigate(`/recipe-info/${recipeId}`, { state: { recipeId: recipeId } });
  };
  return (
    <div className="recipeContainer">
      <div className="recipeNav">
        <DashboardNav dashboardActivePage="recipe" />
      </div>
      <div className="recipeBody">
        <h3>Your Saved Recipes</h3>
        <div className="savedRecipesContainer">
          {savedRecipes.length > 0 ? (
            savedRecipes.map((recipe, index) => {
              return (
                <div
                  className="recipeCard"
                  key={index}
                  onClick={() => recipeInfoClicked(recipe.recipeId)}
                >
                  <img
                    src={recipe.image}
                    alt="recipe"
                    className="savedRecipeImage"
                  />
                  <p>{recipe.title}</p>
                </div>
              );
            })
          ) : (
            <p className="noSavedRecipe">
              {userLoggedIn
                ? "No saved recipes"
                : "Please login or sign up to view saved recipes."}
            </p>
          )}
        </div>
      </div>

      {isLargeScreen ? (
        <Footer />
      ) : (
        <DashboardFooter activeFooter="recipeFooter" />
      )}
    </div>
  );
};

export default Recipe;
