import React from "react";
import "./styles.css";
import Landingnav from "../Nav/Landingnav";
import Footer from "../Footer/Footer";

const Privacy = () => {
  return (
    <div className="privacyContainer">
      <div className="privacyNav">
        <Landingnav />
      </div>
      <div className="privacyBody">
        <div className="privacyBodyContent">
          <h1>Privacy Policy and Your Personal Data</h1>
          <p>
            Your privacy is of paramount importance to us. In our app, we
            respect your personal information and are committed to ensuring its
            confidentiality and security. When you use our app to find and save
            recipes, we only ask for the necessary data—such as your chosen
            ingredients and preferred recipes—to enhance your culinary
            experience. If you opt to create an account, your login details and
            saved recipes are securely stored, allowing you to access your
            personalized selections at any time. Rest assured, we never share or
            sell your personal data to third parties, and we only use it to
            provide and improve the services you receive.
          </p>
          <p className="privacySignOff">-Cook What You Got Team</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
