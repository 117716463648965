import React, { useEffect, useState } from "react";
import "./styles.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DashboardNav from "../Nav/DashboardNav";
import DashboardFooter from "../Footer/DashboardFooter";
import Footer from "../Footer/Footer";
import backIcon from "../../images/dashboard/backIcon.png";
import saved from "../../images/recipeInfo/saved.png";
import unsaved from "../../images/recipeInfo/unsaved.png";
import {
  doc,
  collection,
  addDoc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import { onAuthStateChanged } from "firebase/auth";

const RecipeInfo = () => {
  const location = useLocation();
  const [recipeInfo, setRecipeInfo] = useState({});
  const [savedRecipe, setSavedRecipe] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const [docrefId, setDocRefId] = useState();
  const [userId, setUserid] = useState("");
  const [notification, setNotification] = useState("");
  const [recipeWithNutrients, setRecipeWithNutrients] = useState([]);
  const navigate = useNavigate();
  const recipeIds = location.state.recipeId;
  const calories = location.state.calories;
  const protein = location.state.protein;
  const fat = location.state.fat;
  const carbs = location.state.carbs;

  // console.log("recipeId", recipeIds);
  // console.log("calories", calories);

  useEffect(() => {
    const fetchRecipeInfo = async () => {
      //const url = `https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/${recipeIds}/information`;
      const url = `https://api.spoonacular.com/recipes/${recipeIds}/information?apiKey=${process.env.REACT_APP_SPOONACULAR_API_KEY}`;
      const options = {
        method: "GET",
        // headers: {
        //   "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
        //   "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        // },
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        // console.log(result);
        setRecipeInfo(result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchRecipeInfo();

    //check if user is logged in and set the user id
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserid(user.uid);
      }
    });
    // setUserid(auth?.currentUser?.uid);

    const querySnapshot = async () => {
      const snapshot = await getDocs(collection(db, "recipes"));
      snapshot.forEach((doc) => {
        if (doc.data().recipeId === recipeIds) {
          setSavedRecipe(true);
          setDocRefId(doc.id);
        }
      });
    };
    querySnapshot();
  }, []);

  const saveRecipeClicked = async () => {
    if (userId) {
      setSavedRecipe(true);
      try {
        const docRef = await addDoc(collection(db, "recipes"), {
          title: recipeInfo.title,
          image: recipeInfo.image,
          instructions: recipeInfo.instructions,
          ingredients: recipeInfo.extendedIngredients,
          recipeId: recipeIds,
          userId: userId,
        });
        setDocRefId(docRef.id);
      } catch (error) {
        console.log(error);
      }
    } else {
      setNotification("Please login to save recipes");
      return;
    }
  };

  const unsavedRecipeClicked = async () => {
    setSavedRecipe(false);
    // console.log(docrefId);
    await deleteDoc(doc(db, "recipes", docrefId));
  };
  return (
    <div className="recipeInfoContainer">
      <div className="recipeInfonav">
        <DashboardNav dashboardActivePage="home" />
      </div>
      <div className="recipeInfoBody">
        <div className="recipeInfoTitle">
          <div className="recipeTitleLeft">
            <img
              src={backIcon}
              alt="backIcon"
              className="backIcon"
              onClick={() => navigate("/dashboard")}
            />
            <h3>Reicpe Instructions</h3>
          </div>
          <div className="recipeSaveContainer">
            {savedRecipe ? (
              <button
                className="saveRecipe saveRecipeButton"
                onClick={() => unsavedRecipeClicked()}
              >
                <img src={saved} className="recipeSaveIcon" /> Recipe Saved
              </button>
            ) : (
              <button
                className="saveRecipe unsaveRecipeButton"
                onClick={() => saveRecipeClicked()}
              >
                <img src={unsaved} className="recipeSaveIcon" />
                Save Recipe
              </button>
            )}
            {notification && (
              <p className="notification" onClick={() => navigate("/signup")}>
                {notification}
              </p>
            )}
          </div>
        </div>
        <div className="recipeInstructions">
          <h3>{recipeInfo.title}</h3>

          <div className="readyInfoAndNutrients">
            <p className="readyInInfo">
              Ready in {recipeInfo.readyInMinutes} minutes
            </p>

            <div className="nutrientsInfo">
              <p>{calories} calories,</p>
              <p>{protein} protein,</p>
              <p>{fat} fat,</p>
              <p>{carbs} carbs</p>
            </div>
          </div>

          <img
            src={recipeInfo.image}
            alt={recipeInfo.title}
            className="recipeInfoImage"
          />
          <div className="recipeInfoIngredients">
            <h3>Ingredients</h3>
            <ul>
              {recipeInfo.extendedIngredients &&
                recipeInfo.extendedIngredients.map((ingredient, index) => (
                  <li key={index}>{ingredient.original}</li>
                ))}
            </ul>
          </div>
          <div className="recipeInfoInstructions">
            <h3>Instructions</h3>
            <p>{recipeInfo.instructions}</p>
          </div>
        </div>
      </div>
      {isLargeScreen ? <Footer /> : <DashboardFooter />}
    </div>
  );
};

export default RecipeInfo;
