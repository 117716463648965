import { React, useState } from "react";
import "./styles.css";
import faqPlus from "../../images/homePage/faqPlus.png";
import faqMinus from "../../images/homePage/faqMinus.png";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const togglerFaq = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  const faqList = [
    {
      question: "How does this app work?",
      answer:
        "This app lets you search for recipes based on the ingredients you have at home, and save them for later user. The apps aims to help reduce food waste and make the most of what you have at home.",
    },
    {
      question: "Do I need to pay for this service?",
      answer: "The service is completely free to use!",
    },
    {
      question: "What kind of recipes can I expect to see?",
      answer:
        "The variety of recipes is endless! From breakfast to dinner, and everything in between.",
    },
  ];
  return (
    <div className="faqContainer">
      <h4>FAQ's</h4>
      <div className="faqList">
        {faqList.map((faq, index) => (
          <div className="faqItem" onClick={() => togglerFaq(index)}>
            <div className="faqQuestion">
              <h4>{faq.question}</h4>
              <img
                src={activeIndex === index ? faqMinus : faqPlus}
                className="faqImage"
              />
            </div>
            {activeIndex === index && <p>{faq.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
