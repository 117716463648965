import React from "react";
import { useNavigate } from "react-router-dom";

const Rightnav = ({ activePage, open }) => {
  const navigate = useNavigate();
  return (
    <ul className={`navLinksContainer ${open ? "open" : ""}`}>
      <div className="homeNav">
        <li
          className={`navLink ${activePage == "home" ? "active" : ""}`}
          onClick={(e) => navigate("/")}
        >
          Home
        </li>
        <hr className={`navHr ${activePage == "home" ? "display" : ""}`} />
      </div>

      <div className="aboutUsNav">
        <li
          className={`navLink ${activePage == "aboutus" ? "active" : ""}`}
          onClick={(e) => navigate("/aboutus")}
        >
          About Us
        </li>
        <hr className={`navHr ${activePage == "aboutus" ? "display" : ""}`} />
      </div>
      <div className="loginNav">
        <li
          className={`navLink ${activePage == "login" ? "active" : ""}`}
          onClick={(e) => navigate("/login")}
        >
          Login
        </li>
        <hr className={`navHr ${activePage == "login" ? "display" : ""}`} />
      </div>
      <div className="signUoNav">
        <li
          className={`navLink ${activePage == "signup" ? "active" : ""}`}
          onClick={(e) => navigate("/signup")}
        >
          Sign Up
        </li>
        <hr className={`navHr ${activePage == "signup" ? "display" : ""}`} />
      </div>
    </ul>
  );
};

export default Rightnav;
