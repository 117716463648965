import { React, useEffect, useState } from "react";
import "./styles.css";
import Landingnav from "../Nav/Landingnav";
import Footer from "../Footer/Footer";
import google from "../../images/signup/google.png";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "../../config/firebase";

import {
  signInWithEmailAndPassword,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //regular login
  const loginClicked = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setValidationMessage("");
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      onAuthStateChanged(auth, (user) => {
        if (user) {
          // console.log(user);
          if (user.emailVerified) {
            navigate("/dashboard");
          } else {
            // console.log("User is not verified.");
            setValidationMessage(
              "The email is not verified, please verify your email."
            );
            setEmailValid(false);
            setIsSubmitting(false);
          }
        } else {
          // console.log("No user is signed in.");
        }
      });
    } catch (error) {
      // console.log(error, error.code);
      setIsSubmitting(false);
      if (error.code === "auth/user-not-found") {
        // console.log("No user found with this email.");
        setValidationMessage("No user found with this email, please sign up.");
        setEmailValid(false);
      } else if (error.code === "auth/wrong-password") {
        // console.log("Incorrect password.");
        setValidationMessage("Incorrect password, please try again.");
        setPasswordValid(false);
      } else if (error.code === "auth/invalid-email") {
        // console.log("Invalid email.");
        setValidationMessage("No user found with this email, please sign up.");
        setEmailValid(false);
      } else if (error.code === "auth/invalid-credential") {
        // console.log("Invalid credentials.");
        setValidationMessage(
          "Invalid credentials, please check your email and password and try again."
        );
        setPasswordValid(false);
      }
    }
  };

  const signInWIthGoogleClicked = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // console.log(user);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="loginContainer">
      <div className="navContainerLogin">
        <Landingnav activePage="login" />
      </div>
      <div className="loginBody">
        <div className="loginForm">
          <div className="loginHeading">
            <h1>Log In</h1>
            <hr />
            <p>
              Don't have an account?{" "}
              <span className="signUpSpan" onClick={() => navigate("/signup")}>
                Sign up here
              </span>
            </p>
          </div>
          <button
            className="googleSignInButton"
            onClick={signInWIthGoogleClicked}
          >
            <img src={google} alt="google" className="googleIcon" />
            Sign in with Google
          </button>
          <form className="loginFormSubmit" onSubmit={(e) => loginClicked(e)}>
            <div className="emailLogin">
              <label>Email</label>
              <input type="email" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="passwordLogin">
              <label>Password</label>
              <div className="passwordContainer">
                <input
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="passwordIcon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>
            {!passwordValid && (
              <div className="validationMessage">
                <p className="errorMessage">{validationMessage}</p>
              </div>
            )}
            {!emailValid && (
              <div className="validationMessage">
                <p className="errorMessage">{validationMessage}</p>
              </div>
            )}
            <div className="emailLoginContainer">
              <button
                className="loginButton"
                onClick={loginClicked}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Logging in..." : "Log In"}
              </button>
              <p
                className="forgotPasswordLink"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password
              </p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
