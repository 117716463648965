import React, { useState } from "react";
import "./styles.css";
import Landingnav from "../Nav/Landingnav";
import Footer from "../Footer/Footer";
import { auth } from "../../config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailSet, setEmailSet] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const sendResetEmail = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    var actionCodeSettings = {
      url: "https://cook-what-you-got.web.app/login",
      handleCodeInApp: true,
    };

    await sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        setEmailSet(true);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
      });
  };

  return (
    <div className="forgotPasswordContainer">
      <div className="forgotPasswordNav">
        <Landingnav />
      </div>
      <div className="forgotPasswordBody">
        {!emailSet ? (
          <div className="forgotPasswordMessage">
            <h1>Forgot Password</h1>
            <p>Enter your email to reset your password.</p>
            <form
              className="forgotPasswordForm"
              onSubmit={(e) => sendResetEmail(e)}
            >
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="forgotPasswordButton"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Reset Email"}
              </button>
            </form>
          </div>
        ) : (
          <div className="forgotPasswordMessage">
            <h1>Reset Password</h1>
            <p>
              We have sent a reset link to your email. Please check your email
              to continue.
            </p>
            <p className="resetPasswordLoginLabel">
              <span
                className="resetPasswordLogin"
                onClick={() => navigate("/login")}
              >
                Continue here
              </span>{" "}
              once done.
            </p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
