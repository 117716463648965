import React from "react";
import "./styles.css";
import DashboardFooter from "../Footer/DashboardFooter";
import Footer from "../Footer/Footer";
import Landingnav from "../Nav/Landingnav";
import { useNavigate } from "react-router-dom";

const EmailVerify = () => {
  const navigate = useNavigate();
  return (
    <div className="emailVerifyContainer">
      <div className="emailVerifyNav">
        <Landingnav />
      </div>
      <div className="emailVerifyBody">
        <div className="emailVerifyMessage">
          <h1>Verify Your Email</h1>
          <p>
            We have sent a verification link to your email. Please verify your
            email to continue.
          </p>
          <p className="">
            <span
              className="emailVerifyLogin"
              onClick={() => navigate("/login")}
            >
              Continue to login
            </span>{" "}
            once you're verified.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmailVerify;
