// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMXWMjaU6UFgZvooP3EmfrPHc9bGmRGxc",
  authDomain: "cook-what-you-got.firebaseapp.com",
  projectId: "cook-what-you-got",
  storageBucket: "cook-what-you-got.appspot.com",
  messagingSenderId: "664389580629",
  appId: "1:664389580629:web:91689a23e9acd7169061dc",
  measurementId: "G-496WHR067Y",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
const analytics = getAnalytics(app);
